<template>
    <zw-sidebar @shown="shown" :title="$t('bank.title.bank_transfers_import')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <template v-if="step==='select_tan_mode'">
                    <b-row>
                        <b-col cols="12">
                            <zw-select-group v-model="tanModeIndex"
                                             :label="$t('bank.label.tan_mode')"
                                             :options="tanModes"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-row align-h="end">
                                <b-col sm="6" class="text-sm-right">
                                    <b-button block @click="submitTanMode" variant="primary">
                                        {{ $t('common.button.next') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </template>

                <template v-if="step==='select_tan_device'">
                    <b-row>
                        <b-col cols="12">
                            <zw-select-group v-model="tanDeviceIndex"
                                             :label="$t('bank.label.tan_device')"
                                             :options="tanDevices"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-row align-h="end">
                                <b-col sm="6" class="text-sm-right">
                                    <b-button block @click="submitTanDevice" variant="primary">
                                        {{ $t('common.button.next') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </template>

                <template v-if="step==='select_account'">
                    <b-row>
                        <b-col cols="12">
                            <zw-select-group v-model="accountIndex"
                                             :label="$t('bank.label.account')"
                                             :options="accounts"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6" v-if="accountIndex">
                            <zw-date-group v-model="from_date"
                                           name="from_date.date"
                                           :label="$t('bank.label.from')"
                            ></zw-date-group>
                        </b-col>
                        <b-col cols="6" v-if="accountIndex">
                            <zw-date-group v-model="till_date"
                                           name="from_date.till_date"
                                           :label="$t('bank.label.till')"
                            ></zw-date-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="accountIndex">
                        <b-col sm="12" class="text-xs-center">
                            <b-row align-h="end">
                                <b-col sm="6" class="text-sm-right">
                                    <b-button block @click="submitAccount" variant="primary">
                                        {{ $t('common.button.next') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </template>

                <template v-if="step==='device_confirmation'">
                    <b-row>
                        <b-col v-if="instructions" cols="12">
                            {{ instructions }}
                        </b-col>
                        <b-col v-if="base_64_image" cols="12">
                            <img alt="" :src="base_64_image"/>
                        </b-col>
                        <b-col v-if="image" cols="12">
                            {{ image }}
                        </b-col>
                        <b-col v-if="type!='decoupled'" cols="12">
                            <zw-input-group v-model="tan"
                                            name="tan"
                                            :label="$t('bank.label.tan')"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-row align-h="end">
                                <b-col sm="6" class="text-sm-right">
                                    <b-button block @click="submitTan" variant="primary">
                                        {{ $t('common.button.next') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </template>

                <template v-if="step==='result'">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="card text-white bg-success">
                                <div class="card-body">
                                    <div class="h1 text-muted text-right mb-4">
                                        <font-awesome-icon icon="info"></font-awesome-icon>
                                    </div>
                                    <div class="h4 mb-0">{{ result.credit }}</div>
                                    <small class="text-muted text-uppercase font-weight-bold">
                                        {{ $t('bank.status.credit') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card text-white bg-primary">
                                <div class="card-body">
                                    <div class="h1 text-muted text-right mb-4">
                                        <font-awesome-icon icon="plus"></font-awesome-icon>
                                    </div>
                                    <div class="h4 mb-0">{{ result.credit_mapped }}</div>
                                    <small class="text-muted text-uppercase font-weight-bold">
                                        {{ $t('bank.status.credit_mapped') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card text-white bg-warning">
                                <div class="card-body">
                                    <div class="h1 text-muted text-right mb-4">
                                        <font-awesome-icon icon="ban"></font-awesome-icon>
                                    </div>
                                    <div class="h4 mb-0">{{ result.credit_skipped }}</div>
                                    <small class="text-muted text-uppercase font-weight-bold">
                                        {{ $t('bank.status.credit_skipped') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="card text-white bg-success">
                                <div class="card-body">
                                    <div class="h1 text-muted text-right mb-4">
                                        <font-awesome-icon icon="info"></font-awesome-icon>
                                    </div>
                                    <div class="h4 mb-0">{{ result.debit }}</div>
                                    <small class="text-muted text-uppercase font-weight-bold">
                                        {{ $t('bank.status.debit') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card text-white bg-warning">
                                <div class="card-body">
                                    <div class="h1 text-muted text-right mb-4">
                                        <font-awesome-icon icon="ban"></font-awesome-icon>
                                    </div>
                                    <div class="h4 mb-0">{{ result.debit_skipped }}</div>
                                    <small class="text-muted text-uppercase font-weight-bold">
                                        {{ $t('bank.status.debit_skipped') }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import moment from "moment";

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'bank.label.',
            offeringSearchUrl: window.apiUrl + '/invoices/search',
            payload: {},
            callback: null,
            form: {},
            step: null,
            result: {},
            tanModes: {},
            tanDevices: {},
            accounts: {},
            tanModeIndex: null,
            tanDeviceIndex: null,
            accountIndex: null,
            from_date: null,
            till_date: null,
            tan: null,
            instructions: null,
            device: null,
            image: null,
            base_64_image: null,
            auth_step: null,
            type: null,
            last_syncs: {},
        }
    },
    methods: {
        connect(data) {
            this.loading = true
            window.axios.post(window.apiUrl + '/bank/connect', data, {'skip_loading': true})
                .then(response => {
                    if (response.data.tan_modes) {
                        this.step = 'select_tan_mode'
                        this.tanModes = response.data.tan_modes
                    }
                    if (response.data.tan_devices) {
                        this.step = 'select_tan_device'
                        this.tanDevices = response.data.tan_devices
                    }
                    if (response.data.accounts) {
                        this.step = 'select_account'
                        this.last_syncs = response.data.last_syncs
                        this.accounts = response.data.accounts
                    }
                    if (response.data.result) {
                        this.step = 'result'
                        this.result = response.data.result
                        if (this.callback) {
                            this.callback()
                        }
                    }
                    if (response.data.device || response.data.base_64_image || response.data.instructions) {
                        this.step = 'device_confirmation'
                        this.instructions = response.data.instructions
                        this.device = response.data.device
                        this.image = response.data.image
                        this.base_64_image = response.data.base_64_image
                        this.auth_step = response.data.step
                        this.type = response.data.type || null
                    }
                    if (response.data.error) {
                        this.$root.showMessage(this.$t('common.toasts.error'), response.data.error, 'danger')

                        this.loading = false
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        offeringSelected(transaction, val) {
            transaction.checked = true
            transaction.offering_id = val.offering_id
            transaction.invoice_id = val.invoice_id
        },
        shown() {
            this.connect({})
        },
        submitTanMode() {
            this.connect({tan_mode: this.tanModeIndex})
        },
        submitTanDevice() {
            this.connect({tan_device: this.tanDeviceIndex})
        },
        submitAccount() {
            this.connect({
                account: this.accountIndex,
                from: this.from_date,
                till: this.till_date,
            })
        },
        submitTan() {
            if (!this.tan) {
                this.connect({tan: 'done', auth_step: this.auth_step})
            } else {
                this.connect({tan: this.tan, auth_step: this.auth_step})
            }
        },

        openIncomingInvoice(id) {
            this.$root.$children[0].openModal('incoming-invoices-modal', {
                    id: id,
                }
            )
        },
    },
    watch: {
        accountIndex: function (index) {
            this.from_date = this.last_syncs[index] || null
            this.till_date = moment().format('YYYY-MM-DD')
        },
    }
}
</script>